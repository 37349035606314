import { createSlice } from "@reduxjs/toolkit";

export type ErrorBoundaryState = {
  errors: string[];
  apiErrors: Record<string, string>;
};

const initialState: ErrorBoundaryState = {
  errors: [],
  apiErrors: {},
};

/** Error Boundary Slice */
const { reducer, actions } = createSlice({
  name: "errorBoundarySlice",
  initialState,
  reducers: {
    addError: (state, { payload }) => {
      // In the future we can add logging here as well
      state.errors = [...state.errors, payload];
    },
    refreshPage: (state) => {
      window.location.href = "/";
    },

    addApiError: (state, { payload }) => {
      state.apiErrors[payload.key] = payload.value;
    },
    removeApiError: (state, { payload }) => {
      delete state.apiErrors[payload];
    },
  },
});

export const { addError, refreshPage, addApiError, removeApiError } = actions;

export default reducer;
