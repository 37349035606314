import React, { useEffect } from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Tag from "@amzn/meridian/tag";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  AuditStatus,
  setCurrentPage,
  setItemPerPage,
  ReviewStatus,
  getReviewedCaseCSV,
  resetListViewPage,
} from "src/store/auditSlice";
import { download } from "src/store/modelLifeCycleSlice";
import meridianColors from "@amzn/meridian-tokens/base/color";
import DonutChart from "src/components/audit/donutChart";
import PaginationWithDropdown from "src/components/audit/paginationWithDropdown";
import { setSelectedModelId } from "src/store/modelManagementSlice";
import Coachmark from "@amzn/meridian/coachmark";
import _ from "lodash";
import "./auditIncidentListView.scss";
import {
  AuditDataView,
  getAuditDataListView,
  getAuditJobById,
} from "src/store/auditSlice";
import { isValidRange } from "src/utils/numberUtil";
import { formatTimestampToDate } from "src/utils/dateUtil";
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large";
import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link";
import { findNextUnreviewedCase } from "src/components/audit/helpers";

const AuditIncidentListView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { auditWorkflowId } = useParams();

  const {
    selectedAuditJob,
    auditDataListView,
    currentPage,
    itemsPerPage,
    getAuditJobLoading,
  } = useAppSelector((state) => state.auditSlice);
  const { token } = useAppSelector((state) => state.user);
  const { selectedModel } = useAppSelector((state) => state.modelSlice);

  const getAuditDataStatus = (auditData: AuditDataView) => {
    return auditData.status === AuditStatus.REVIEWED
      ? ReviewStatus.YES
      : ReviewStatus.NO;
  };

  // triggerred when review page redirect to list view
  useEffect(() => {
    dispatch(resetListViewPage());
  }, []);

  useEffect(() => {
    if (auditWorkflowId && token) {
      dispatch(getAuditDataListView(auditWorkflowId));
      dispatch(getAuditJobById(auditWorkflowId));
    }
  }, [auditWorkflowId, token]);

  useEffect(() => {
    if (selectedAuditJob.modelId) {
      dispatch(setSelectedModelId(selectedAuditJob.modelId));
    }
  }, [selectedAuditJob.modelId]);

  useEffect(() => {
    if (getAuditJobLoading === "rejected") {
      navigate(`/audit`);
    }
  }, [getAuditJobLoading]);

  const numberOfPages = Math.ceil(auditDataListView.length / itemsPerPage);
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = Math.min(
    auditDataListView.length,
    firstVisibleIndex + itemsPerPage - 1,
  );

  const tableHeaderRowNames = [
    "Case ID",
    "Insights Generated",
    "Reviewed",
    "Reviewer",
  ];

  return (
    <Row widths={["75%", "25%"]}>
      <Column spacingInset={"0 500 500 500"}>
        <BreadcrumbGroup>
          <Breadcrumb onClick={() => navigate(`/audit`)}>
            Audit workflow
          </Breadcrumb>
          <Breadcrumb onClick={() => navigate(`/audit/${auditWorkflowId}`)}>
            {selectedAuditJob.modelName} - Processed
          </Breadcrumb>
        </BreadcrumbGroup>
        <Row widths={["65%", "35%"]}>
          <Row spacing={"400"}>
            <Column alignmentHorizontal="start" spacing={"300"}>
              <Text type={"d50"}>Base incidents - Processed</Text>
              <Row spacing={"600"}>
                <Text type="b100">Model Id: {selectedAuditJob.modelId}</Text>
                <Text type="b100">
                  Total Fields: {Object.keys(selectedModel.fields).length}
                </Text>
                <Text type="b100">{`Processed Date: ${formatTimestampToDate(selectedAuditJob.timestamp)}`}</Text>
              </Row>
            </Column>
          </Row>
          <Row alignmentHorizontal="end" widths={["50%", "50%"]}>
            <Button
              type="tertiary"
              onClick={() => {
                dispatch(
                  getReviewedCaseCSV({
                    auditWorkflowId: selectedAuditJob.id,
                    modelId: selectedAuditJob.modelId,
                  }),
                );
              }}
              data-cy={"reviewed-cases__button"}
            >
              <Icon tokens={downloadLargeTokens}></Icon>
              Reviewed Cases
            </Button>
            {auditDataListView.length > 0 && (
              <Button
                onClick={() => {
                  const next = findNextUnreviewedCase(auditDataListView);
                  if (next) {
                    navigate(`/audit/${auditWorkflowId}/${next.incidentId}`);
                  } else {
                    navigate(
                      `/audit/${auditWorkflowId}/${auditDataListView[0].incidentId}`,
                    );
                  }
                }}
                data-cy={"reviewed__button"}
              >
                Review
              </Button>
            )}
          </Row>
        </Row>
        <Column height="65vh" overflowY="auto">
          <Table
            showDividers={true}
            fixHeaderRows={true}
            stickyHeaderColumn={true}
            headerRows={1}
          >
            <TableRow backgroundColor={meridianColors.colorGray50}>
              {tableHeaderRowNames.map((name) => (
                <TableCell>
                  {name === "Insights Generated" ? (
                    <Row spacing={"none"}>
                      <Coachmark popoverPosition="top" animating={false}>
                        <Text>
                          The number represents: number of field extracted by
                          LLM excluding 'inadequate' result / number of total
                          field part of this model
                        </Text>
                      </Coachmark>
                      <Text type="h200">{name}</Text>
                    </Row>
                  ) : (
                    <Text type="h200">{name}</Text>
                  )}
                </TableCell>
              ))}
            </TableRow>
            {isValidRange(
              firstVisibleIndex,
              lastVisibleIndex,
              0,
              auditDataListView.length,
            ) &&
              _.range(firstVisibleIndex, lastVisibleIndex).map((index) => {
                const totalField = Object.keys(
                  selectedModel.fields,
                ).length.toString();
                const numOfLLMAnswerInadequate =
                  auditDataListView[index].generatedField;
                const tagValue = `${numOfLLMAnswerInadequate}/${totalField}`;
                return (
                  auditDataListView[index] && (
                    <TableRow
                      highlightOnHover
                      key={auditDataListView[index].caseId}
                      data-cy={`audit-data-list-view-${index}__row`}
                    >
                      <TableCell>
                        <Link
                          type="secondary"
                          onClick={() => {
                            navigate(
                              `/audit/${auditWorkflowId}/${auditDataListView[index].incidentId}`,
                            );
                          }}
                        >
                          {auditDataListView[index].caseId}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Tag type={"neutral"}>{tagValue}</Tag>
                      </TableCell>
                      <TableCell>
                        {getAuditDataStatus(auditDataListView[index])}
                      </TableCell>
                      <TableCell>
                        {auditDataListView[index].reviewer || ""}
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
          </Table>
        </Column>
        <PaginationWithDropdown
          currentPage={currentPage}
          totalPages={numberOfPages}
          perPage={itemsPerPage}
          setPerPage={(pageNum) => {
            dispatch(setItemPerPage(pageNum));
            dispatch(setCurrentPage(1));
          }}
          setCurrentPage={(newPage) => dispatch(setCurrentPage(newPage))}
        />
      </Column>
      <Column
        height={"92vh"}
        className="incident-summary"
        alignmentHorizontal="start"
        spacingInset={"500"}
      >
        <Text type="h300">Incident Summary</Text>
        <DonutChart />
        <Button
          type="tertiary"
          onClick={() =>
            dispatch(
              download({
                jobId: selectedAuditJob.id,
                s3URL: selectedAuditJob.failedIncidentsS3FilePath,
                fileName: "failed_incidents.csv",
              }),
            )
          }
          data-cy={"download-failed-cases__button"}
        >
          <Icon tokens={downloadLargeTokens}></Icon>
          Download Failed Cases
        </Button>
      </Column>
    </Row>
  );
};

export default AuditIncidentListView;
