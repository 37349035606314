import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import ModelService from "src/services/modelService";
import { DUMMY_MODEL_ID } from "src/constants";
import { LOADING_STATE } from "src/types/constants";

export interface FieldExample {
  input: string;
  output: string[];
}

export enum FieldType {
  selection = "selection",
  freeText = "free_text",
}

export enum ModelStatus {
  active = "Active",
  inactive = "Inactive",
}

export interface Field {
  id: string;
  name: string;
  description: string;
  type: FieldType | string;
  many: boolean;
  examples: FieldExample[];
  options: string[];
}

export interface Model {
  id: string;
  name: string;
  fields: Record<string, Field>;
  status: ModelStatus | undefined;
  isRagEnabled: boolean | undefined;
}

export interface ModelView {
  id: string;
  name: string;
  numberOfLabels: number;
}

export type ModelManagementPageState = {
  models: Record<string, Model>;
  selectedModelId: string;
  selectedFieldId: string;
  selectedModel: Model;
  modelView: ModelView[];
  errors: Record<string, string>;
  isCreatingField: boolean;
  isCreateModel: boolean;
  getModelByIdLoading: LOADING_STATE;
};

const InitialModel: Model = {
  id: "",
  name: "",
  fields: {},
  status: undefined,
  isRagEnabled: undefined,
};

export const initialField: Field = {
  id: "",
  name: "",
  description: "",
  type: "",
  examples: [],
  options: [],
  many: false,
};

export const MODEL_NAME_EMPTY = "Model name cannot be empty";

export const MODEL_NO_FIELD = "Model must have at least one field";

export const FIELD_NAME_EMPTY = "Field name cannot be empty";

export const FIELD_DESCRIPTION_EMPTY = "Description cannot be empty";

export const FIELD_TYPE_EMPTY = "Field must has one type";

export const FIELD_OPTION_EMPTY = "Field option cannot be empty";

export const FIELD_EXAMPLE_INPUT_EMPTY = "Example input cannot be empty";

export const FIELD_EXAMPLE_INPUT_TOO_LONG =
  "Example input length cannot longer than 300 characters";

export const FIELD_EXAMPLE_OUTPUT_EMPTY = "Example output cannot be empty";

export const EXAMPLE_OPTION_INVALID =
  "Example output must be one of the options";

export const getModelNameKey = (modelId: string) => {
  return modelId + "_NAME";
};

export const getModelFieldKey = (modelId: string) => {
  return modelId + "_FIELD";
};

export const getFieldNameKey = (fieldId: string) => {
  return fieldId + "_NAME";
};

export const getFieldTypeKey = (fieldId: string) => {
  return fieldId + "_Type";
};

export const getFieldDescriptionKey = (fieldId: string) => {
  return fieldId + "_DESCRIPTION";
};

export const getFieldOptionsKey = (fieldId: string) => {
  return fieldId + "_OPTIONS";
};

export const getFieldExampleKey = (
  fieldId: string,
  index: number,
  type: string,
) => {
  return fieldId + "_EXAMPLE_" + index + type;
};

export const getModels = createAsyncThunk(
  "modelManagement/getModels",
  async () => {
    const { data } = await ModelService.getModels();
    return data;
  },
);

export const getModelById = createAsyncThunk(
  "modelManagement/getModelById",
  async (modelId: string) => {
    const { data } = await ModelService.getModelById(modelId);
    return data;
  },
);

export const validateModel = (model: Model) => {
  const errors: Record<string, string> = {};
  if (!model.name) {
    errors[getModelNameKey(model.id)] = MODEL_NAME_EMPTY;
  }
  if (Object.entries(model.fields).length === 0) {
    errors[getModelFieldKey(model.id)] = MODEL_NO_FIELD;
  }
  Object.values(model.fields).forEach((field: Field) => {
    if (!field.name) {
      errors[getFieldNameKey(field.id)] = FIELD_NAME_EMPTY;
    }
    if (!field.description) {
      errors[getFieldDescriptionKey(field.id)] = FIELD_DESCRIPTION_EMPTY;
    }
    if (!field.type) {
      errors[getFieldTypeKey(field.id)] = FIELD_TYPE_EMPTY;
    }
    if (field.type === FieldType.selection && field.options.length === 0) {
      errors[getFieldOptionsKey(field.id)] = FIELD_OPTION_EMPTY;
    }
    field.examples.forEach((example, index) => {
      if (!example.input) {
        errors[getFieldExampleKey(field.id, index, "INPUT")] =
          FIELD_EXAMPLE_INPUT_EMPTY;
      } else if (example.input.length > 3000) {
        errors[getFieldExampleKey(field.id, index, "INPUT")] =
          FIELD_EXAMPLE_INPUT_TOO_LONG;
      }
    });
  });
  return errors;
};

export const validateAndUpsertModel = createAsyncThunk(
  "modelManagement/upsertModel",
  async (model: Model, { getState }) => {
    const errors = validateModel(model);
    if (Object.entries(errors).length === 0) {
      const { data } = await ModelService.upsertModel(toModelPayload(model));
      return data;
    }
  },
);

export const toLabelPayload = (field: Field) => {
  return {
    ...field,
    options: Array.from(new Set(field.options.map((option) => option.trim()))),
    examples: field.examples.map((example) => {
      return {
        input: example.input,
        output: field.many
          ? example.output.filter((option: string) => option !== "")
          : example.output[0],
      };
    }),
  };
};

export const toModelPayload = (model: Model) => {
  if (model.id === DUMMY_MODEL_ID) {
    return {
      id: undefined,
      name: model.name,
      labels: Object.keys(model.fields).map((id) =>
        toLabelPayload(model.fields[id]),
      ),
    };
  } else {
    return {
      id: model.id,
      name: model.name,
      labels: Object.keys(model.fields).map((id) =>
        toLabelPayload(model.fields[id]),
      ),
    };
  }
};

const initialState: ModelManagementPageState = {
  models: {},
  selectedModelId: "",
  selectedFieldId: "",
  selectedModel: InitialModel,
  modelView: [],
  errors: {},
  isCreatingField: false,
  isCreateModel: false,
  getModelByIdLoading: "pending",
};

/** Job Config Page Slice */
const { reducer, actions } = createSlice({
  name: "modelConfigPageState",
  initialState,
  reducers: {
    setSelectedModelId: (state, action) => {
      state.selectedModelId = action.payload;
    },
    setSelectedFieldId: (state, action) => {
      state.selectedFieldId = action.payload;
    },
    setModelName: (state, action) => {
      state.selectedModel.name = action.payload;
    },
    setModel: (state, action) => {
      state.selectedModel = action.payload;
    },
    updateField: (state, action) => {
      state.selectedModel.fields[action.payload.id] = action.payload;
    },
    setFieldName: (state, action) => {
      state.selectedModel.fields[state.selectedFieldId].name = action.payload;
    },
    setFieldDescription: (state, action) => {
      state.selectedModel.fields[state.selectedFieldId].description =
        action.payload;
    },
    setFieldType: (state, action) => {
      state.selectedModel.fields[state.selectedFieldId].type = action.payload;
    },
    setFieldOptions: (state, action) => {
      state.selectedModel.fields[state.selectedFieldId].options =
        action.payload.split("\n");
    },
    setFieldExamples: (state, action) => {
      const index = action.payload.index;
      if (action.payload.input !== undefined) {
        state.selectedModel.fields[state.selectedFieldId].examples[
          index
        ].input = action.payload.input;
      }
      if (action.payload.output !== undefined) {
        if (state.selectedModel.fields[state.selectedFieldId].many) {
          action.payload.output.filter((option: string) => option !== "");
          state.selectedModel.fields[state.selectedFieldId].examples[
            index
          ].output = action.payload.output;
        } else {
          state.selectedModel.fields[state.selectedFieldId].examples[
            index
          ].output[0] = action.payload.output;
        }
      }
    },
    setFieldMany: (state, action) => {
      if (action.payload === undefined) {
        // this is for existed data don't have many field
        state.selectedModel.fields[state.selectedFieldId].many = true;
      } else {
        state.selectedModel.fields[state.selectedFieldId].many = action.payload;
      }
    },
    addFieldExample: (state) => {
      state.selectedModel.fields[state.selectedFieldId].examples.push({
        input: "",
        output: [],
      });
    },
    removeFieldExample: (state, action) => {
      state.selectedModel.fields[state.selectedFieldId].examples.splice(
        action.payload,
        1,
      );
    },
    addNewField: (state) => {
      const newField = {
        id: uuidv4(),
        name: "",
        description: "",
        options: [],
        type: "",
        many: false,
        examples: [{ input: "", output: [] }],
      };
      state.selectedModel.fields[newField.id] = newField;
      state.selectedFieldId = newField.id;
      state.isCreatingField = true;
    },
    addNewModel: (state) => {
      state.selectedModelId = DUMMY_MODEL_ID;
      state.selectedModel = {
        id: DUMMY_MODEL_ID,
        name: "",
        fields: {},
        status: ModelStatus.active,
        isRagEnabled: undefined,
      };
      state.selectedFieldId = "";
      state.isCreateModel = true;
      state.isCreatingField = false;
    },
    resetField: (state) => {
      state.selectedFieldId = "";
      state.isCreatingField = false;
    },
    removeField: (state) => {
      delete state.selectedModel.fields[state.selectedFieldId];
      state.selectedFieldId = "";
      state.isCreateModel = false;
      state.isCreatingField = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getModels.fulfilled, (state, { payload }) => {
      state.modelView = payload.sort((a: ModelView, b: ModelView) =>
        a.name.localeCompare(b.name),
      );
    });
    builder.addCase(getModels.pending, (state, { payload }) => {
      state.getModelByIdLoading = "pending";
    });
    builder.addCase(getModelById.fulfilled, (state, { payload }) => {
      const fieldMap: Record<string, Field> = {};
      payload.labels.forEach((field: any, index: number) => {
        const tmpId = payload.id + field.name + index;
        field.options.sort((a: string, b: string) =>
          a.localeCompare(b, undefined, { sensitivity: "base" }),
        );
        field.examples.forEach((example: any) => {
          if (!field.many && typeof example.output === "string") {
            example.output = [example.output];
          }
        });
        fieldMap[tmpId] = { id: tmpId, ...field };
      });
      state.models[payload.id] = { ...payload, fields: fieldMap };
      state.selectedModelId = payload.id;
      state.selectedModel = {
        ...payload,
        fields: fieldMap,
      };
      state.getModelByIdLoading = "fulfilled";
    });
    builder.addCase(validateAndUpsertModel.pending, (state, { payload }) => {
      state.errors = validateModel(state.selectedModel);
    });
    builder.addCase(validateAndUpsertModel.fulfilled, (state, { payload }) => {
      const fieldMap: Record<string, Field> = {};
      const selectedFieldName =
        state.selectedModel.fields[state.selectedFieldId].name;
      payload.labels.forEach((field: any, index: number) => {
        const tmpId = payload.id + field.name + index;
        if (field.name === selectedFieldName) {
          state.selectedFieldId = tmpId;
        }
        field.options.sort((a: string, b: string) =>
          a.localeCompare(b, undefined, { sensitivity: "base" }),
        );
        field.examples.forEach((example: any) => {
          if (!field.many && typeof example.output === "string") {
            example.output = [example.output];
          }
        });

        fieldMap[tmpId] = { id: tmpId, ...field };
      });
      state.models[payload.id] = { ...payload, fields: fieldMap };
      state.selectedModelId = payload.id;
      state.selectedModel = {
        ...payload,
        fields: fieldMap,
      };
      state.isCreateModel = false;
      state.isCreatingField = false;
    });
  },
});

export const {
  setSelectedModelId,
  setSelectedFieldId,
  setModelName,
  setFieldName,
  setFieldType,
  setFieldDescription,
  setFieldOptions,
  setFieldExamples,
  addFieldExample,
  removeFieldExample,
  addNewField,
  addNewModel,
  resetField,
  removeField,
  setFieldMany,
} = actions;

export default reducer;
