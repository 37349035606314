import apiClient from "src/utils/apiClient";
import FileService from "src/services/FileService";
import { downloadFile, createFileUrl } from "src/utils/fileUtil";

type DownloadPayload = {
  jobId: string;
  s3URL: string;
  fileName: string;
};

class CommonService {
  async getPreSignUrlAndDownload({ jobId, s3URL, fileName }: DownloadPayload) {
    const { data } = await apiClient.get(
      `/extractions/batch/download/?job_id=${jobId}&s3_path=${s3URL}`,
    );
    await FileService.downloadFromPreSignedUrl(data).then((response) => {
      response.blob().then((blob) => {
        blob.text().then((blobString) => {
          downloadFile(createFileUrl(blobString, "csv"), fileName);
        });
      });
    });
  }

  async getModelLifeCyclePreSignedUrlAndDownload({
    jobId,
    s3URL,
    fileName,
  }: DownloadPayload) {
    const { data } = await apiClient.get(
      `/model_life_cycle/batch/download/?job_id=${jobId}&s3_path=${s3URL}`,
    );

    await FileService.downloadFromPreSignedUrl(data).then((response) => {
      response.blob().then((blob) => {
        blob.text().then((blobString) => {
          downloadFile(createFileUrl(blobString, "csv"), fileName);
        });
      });
    });
  }
}

export default new CommonService();
