import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LOADING_STATE } from "src/types/constants";
import AuditWorkflowService from "src/services/auditWorkflowServeice";
import { AuditJob } from "./auditDataUploadSlice";
import { createFileUrl, downloadFile } from "src/utils/fileUtil";
import { INADEQUATE_INFORMATION } from "src/components/audit/helpers";

export enum AuditStatus {
  REVIEWED = "Reviewed",
  UNREVIEWED = "Unreviewed",
  MATCHED = "Matched",
}

export enum ReviewStatus {
  YES = "YES",
  NO = "NO",
}

export const NO_OBJECT_INVOLVED = "No object involved";

const MULTI_OPTION_DELIMITER = "|";

export type AuditField = {
  fieldName: string;
  austinAnswer: string;
  llmClarification: string;
  llmAnswer: string;
  confirmedAnswer: string | string[];
  isHierarchicalMatched: boolean;
};

export type AuditData = {
  incidentId: string;
  caseId: string;
  modelId: string;
  incidentDescription: string;
  auditFields: AuditField[];
  auditWorkflowId: string;
  generatedField: number;
  timestamp: string;
  status: AuditStatus;
  reviewer: string;
};

export type Incident = {
  incidentId: string;
  url: string;
};

export type AuditDataView = {
  caseId: string;
  incidentId: string;
  generatedField: number;
  status: AuditStatus;
  reviewer: string;
};

export type AuditState = {
  selectedAuditJob: AuditJob;
  auditListIndex: number;
  auditDataListView: AuditDataView[];
  auditDataTotalCount: number;
  auditDataReviewedCount: number;
  selectedAuditData: AuditData;
  incident: Incident;
  itemsPerPage: number;
  currentPage: number;
  auditDataSequentialId: number;
  auditDataFieldIndex: number;
  getAuditJobLoading: LOADING_STATE;
  getListViewLoading: LOADING_STATE;
  getAuditDataLoading: LOADING_STATE;
  getAuditDataStatistics: LOADING_STATE;
  updateAuditDataLoading: LOADING_STATE;
  getIncidentDataLoading: LOADING_STATE;
};

export const InitialAuditJob: AuditJob = {
  id: "",
  modelId: "",
  modelName: "",
  ownerAlias: "",
  s3OutputFilePath: "",
  inputFileName: "",
  failedIncidentsS3FilePath: "",
  status: "",
  timestamp: "",
  linesProcessed: 0,
  linesFailed: 0,
};

export const initialAuditData: AuditData = {
  incidentId: "",
  caseId: "",
  modelId: "",
  incidentDescription: "",
  auditFields: [],
  auditWorkflowId: "",
  generatedField: 0,
  timestamp: "",
  status: AuditStatus.UNREVIEWED,
  reviewer: "",
};

export const initialIncidentData: Incident = {
  incidentId: "",
  url: "",
};

const initialState: AuditState = {
  selectedAuditJob: InitialAuditJob,
  auditListIndex: -1,
  auditDataListView: [],
  auditDataTotalCount: 0,
  auditDataReviewedCount: 0,
  selectedAuditData: initialAuditData,
  incident: initialIncidentData,
  itemsPerPage: 50,
  currentPage: 1,
  auditDataSequentialId: -1,
  auditDataFieldIndex: -1,
  getAuditJobLoading: "idle",
  getListViewLoading: "idle",
  getAuditDataLoading: "idle",
  updateAuditDataLoading: "idle",
  getAuditDataStatistics: "idle",
  getIncidentDataLoading: "idle",
};

export const getAuditDataListView = createAsyncThunk(
  "audit/getAuditDataListView",
  async (auditWorkflowId: string) => {
    const { data } =
      await AuditWorkflowService.getAuditDataListView(auditWorkflowId);
    return data;
  },
);

export const getAuditJobById = createAsyncThunk(
  "audit/getAuditJobById",
  async (auditWorkflowId: string) => {
    const { data } =
      await AuditWorkflowService.getAuditJobById(auditWorkflowId);
    return data;
  },
);

export const getAuditData = createAsyncThunk(
  "audit/getAuditData",
  async (payload: { auditWorkflowId: string; incidentId: string }) => {
    const { data } = await AuditWorkflowService.getAuditData(payload);
    return data;
  },
);

export const getIncidentData = createAsyncThunk(
  "audit/getIncidentData",
  async (payload: string) => {
    const { data } = await AuditWorkflowService.getIncident(payload);
    return data;
  },
);

export const getReviewedCaseCSV = createAsyncThunk(
  "audit/getReviewedCaseCSV",
  async (payload: { auditWorkflowId: string; modelId: string }) => {
    const { data } = await AuditWorkflowService.getReviewedCasesCSV(payload);
    return data;
  },
);

export const updateAuditData = createAsyncThunk(
  "audit/updateAuditData",
  async (payload: { auditData: AuditData; reviewer: string }) => {
    const { data } = await AuditWorkflowService.updateAuditData({
      ...payload.auditData,
      auditFields: payload.auditData.auditFields.map((auditField: AuditField) =>
        toAuditFields(auditField),
      ),
      reviewer: payload.reviewer,
    });
    return data;
  },
);

export const toAuditFieldStatus = (item: any) => {
  if (item.confirmedAnswer) {
    return item.auditStatus;
  } else if (item.llmAnswer === item.userUploadedAnswer) {
    return AuditStatus.MATCHED;
  } else {
    return AuditStatus.UNREVIEWED;
  }
};

export const toAuditFields = (item: AuditField) => {
  return {
    ...item,
    confirmedAnswer:
      typeof item.confirmedAnswer === "string"
        ? item.confirmedAnswer || NO_OBJECT_INVOLVED
        : item.confirmedAnswer.join(MULTI_OPTION_DELIMITER),
  };
};

export const toAuditDataPayload = (item: AuditData, reviewer: string) => {
  return {
    ...item,
    auditFields: item.auditFields.map((auditField: AuditField) =>
      toAuditFields(auditField),
    ),
    reviewer: reviewer,
  };
};

export const setLLMAnswerForUnreviewedAuditData = (auditData: AuditData) => {
  if (auditData.status === AuditStatus.UNREVIEWED) {
    auditData.auditFields.forEach((auditField) => {
      if (!auditField.confirmedAnswer) {
        auditField.confirmedAnswer =
          auditField.llmAnswer || INADEQUATE_INFORMATION;
      }
    });
  }
  return auditData;
};

/** Data Extraction Page Slice */
const { reducer, actions } = createSlice({
  name: "AuditListViewPageSlice",
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setItemPerPage: (state, { payload }) => {
      state.itemsPerPage = payload;
    },
    setSelectAuditJob: (state, { payload }) => {
      state.selectedAuditJob = payload;
    },
    setGroundTruthSequentialId: (state, { payload }) => {
      state.auditDataSequentialId = payload;
    },
    setFieldIndex: (state, { payload }) => {
      state.auditDataFieldIndex = payload;
    },
    setFieldConfirmedAnswer: (state, { payload }) => {
      state.selectedAuditData.auditFields[payload.fieldIndex].confirmedAnswer =
        payload.confirmedAnswer;
    },
    resetListViewPage: (state) => {
      state.getAuditDataLoading = "idle";
      state.updateAuditDataLoading = "idle";
      state.getAuditDataStatistics = "idle";
      state.getAuditJobLoading = "idle";
      state.getListViewLoading = "idle";
    },
    resetAuditReviewPage: (state) => {
      state.getAuditDataLoading = "idle";
      state.updateAuditDataLoading = "idle";
      state.getAuditDataStatistics = "idle";
      state.getAuditJobLoading = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAuditDataListView.pending, (state) => {
      state.getListViewLoading = "pending";
    });
    builder.addCase(getAuditDataListView.fulfilled, (state, { payload }) => {
      state.getListViewLoading = "fulfilled";
      state.auditDataListView = payload.sort(
        (a: AuditDataView, b: AuditDataView) => {
          // Sort by status (unreview first, then base on case id)
          return a.status === b.status
            ? a.caseId.localeCompare(b.caseId)
            : a.status === AuditStatus.UNREVIEWED
              ? -1
              : 1;
        },
      );
    });
    builder.addCase(getAuditDataListView.rejected, (state) => {
      state.getListViewLoading = "rejected";
    });

    builder.addCase(updateAuditData.pending, (state) => {
      state.updateAuditDataLoading = "pending";
    });
    builder.addCase(updateAuditData.fulfilled, (state, { payload }) => {
      state.updateAuditDataLoading = "fulfilled";
      const index = state.auditDataListView.findIndex(
        (auditDataView: AuditDataView) =>
          auditDataView.incidentId === payload.incidentId,
      );
      if (index !== -1) {
        state.auditDataListView[index].status = payload.status;
      }
    });
    builder.addCase(updateAuditData.rejected, (state) => {
      state.updateAuditDataLoading = "rejected";
    });

    builder.addCase(getAuditData.pending, (state) => {
      state.getAuditDataLoading = "pending";
    });
    builder.addCase(getAuditData.fulfilled, (state, { payload }) => {
      state.getAuditDataLoading = "fulfilled";
      state.selectedAuditData = setLLMAnswerForUnreviewedAuditData(payload);
    });
    builder.addCase(getAuditData.rejected, (state) => {
      state.getAuditDataLoading = "rejected";
    });
    builder.addCase(getAuditJobById.pending, (state, { payload }) => {
      state.getAuditJobLoading = "pending";
    });
    builder.addCase(getAuditJobById.rejected, (state, { payload }) => {
      state.getAuditJobLoading = "rejected";
    });
    builder.addCase(getAuditJobById.fulfilled, (state, { payload }) => {
      state.selectedAuditJob = payload;
      state.getAuditJobLoading = "fulfilled";
    });
    builder.addCase(getReviewedCaseCSV.fulfilled, (state, { payload }) => {
      if (payload) {
        // download file
        downloadFile(createFileUrl(payload, "text/csv"), "reviewed_case.csv");
      }
    });
    builder.addCase(getIncidentData.fulfilled, (state, { payload }) => {
      state.incident = payload;
    });
  },
});

export const {
  setSelectAuditJob,
  setFieldIndex,
  setFieldConfirmedAnswer,
  setCurrentPage,
  setItemPerPage,
  resetAuditReviewPage,
  resetListViewPage,
} = actions;

export default reducer;
