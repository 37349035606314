import Textarea from "@amzn/meridian/textarea";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import editTokens from "@amzn/meridian-tokens/base/icon/edit";
import ExtractionResult from "src/components/dataExtraction/extractionResult";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  clearExtractionOutput,
  resetExtractionApi,
  setIsExtractionValid,
  setTextDescription,
  singleExtraction,
  setGenerateLLMClarification,
} from "src/store/dataExtractionSlice";
import FieldViewSection from "src/components/shared/fieldViewSection/fieldViewSection";
import Icon from "@amzn/meridian/icon";
import { useNavigate } from "react-router-dom";
import Alert from "@amzn/meridian/alert";
import React, { useEffect } from "react";
import Loader from "@amzn/meridian/loader";
import { TOAST_TIMEOUT } from "src/config/Toast";
import { createToast } from "src/store/toastsSlice";
import ScrollableSection from "src/components/shared/components/scrollableSection";
import Toggle from "@amzn/meridian/toggle";
import ModelSelect from "src/components/shared/modelSelect/modelSelect";

const DataExtraction = () => {
  const dispatch = useAppDispatch();
  const {
    textDescription,
    isExtractionValid,
    generateLLMClarification,
    isPending,
    isFulfilled,
    isError,
  } = useAppSelector((state) => state.dataExtractionSlice);
  const { selectedModelId } = useAppSelector((state) => state.modelSlice);
  const navigate = useNavigate();
  useEffect(() => {
    if (isError) {
      dispatch(
        createToast({
          type: "error",
          message: "Extraction failed",
          timeout: TOAST_TIMEOUT,
        }),
      );
      dispatch(resetExtractionApi());
    }
  }, [isError]);

  return (
    <Row
      spacingInset={"500"}
      alignmentVertical="top"
      widths={["grid-5", "fit", "grid-6"]}
    >
      <ScrollableSection>
        <Text fontFamily="bookerly" type={"d50"}>
          Enter incident...
        </Text>
        <Row spacingInset={"none 500 none none"} widths={"fill"}>
          <Textarea
            value={textDescription}
            onChange={(description) =>
              dispatch(setTextDescription(description))
            }
            rows={10}
            placeholder="Enter incident description..."
            error={!isExtractionValid && textDescription === ""}
            resize="none"
            data-cy={"incident_description__textarea"}
          />
        </Row>
        {textDescription === "" && !isExtractionValid && (
          <Row spacingInset={"none 500 none none"} widths={"fill"}>
            <Alert type="error" size="small">
              Please enter incident description
            </Alert>
          </Row>
        )}
        <Row
          width="80%"
          widths={"fill"}
          alignmentHorizontal={"start"}
          alignmentVertical={"bottom"}
        >
          <ModelSelect />
          {selectedModelId !== "" && (
            <Button
              type={"tertiary"}
              onClick={() => navigate("/modelManagement")}
            >
              <Icon tokens={editTokens} />
              Edit
            </Button>
          )}
          {selectedModelId === "" && !isExtractionValid && (
            <Alert type="error" size="small">
              Please select model
            </Alert>
          )}
        </Row>
        <Row width={"100%"} alignmentHorizontal={"start"} widths={"fill"}>
          <Column>
            <Text>Generate answer explanation</Text>
            <Row>
              <Toggle
                checked={generateLLMClarification}
                onChange={() => {
                  dispatch(
                    setGenerateLLMClarification(!generateLLMClarification),
                  );
                }}
                data-cy={"generate_answer_explanation__toggle"}
              ></Toggle>
            </Row>
          </Column>
        </Row>
        <Row width="100%" alignmentHorizontal={"start"}>
          <FieldViewSection />
        </Row>
        <Row width="40%" widths={"fill"} alignmentHorizontal={"start"}>
          <Button
            onClick={() => {
              if (textDescription === "" || selectedModelId === "") {
                dispatch(setIsExtractionValid(false));
              } else {
                dispatch(setIsExtractionValid(true));
                dispatch(
                  singleExtraction({
                    modelId: selectedModelId,
                    text: textDescription,
                    generateLLMClarification: generateLLMClarification,
                  }),
                );
                dispatch(clearExtractionOutput());
              }
            }}
            disabled={isPending}
            size={"medium"}
            data-cy={"extract-incident__button"}
          >
            Extract
          </Button>
        </Row>
      </ScrollableSection>
      <div className="split-line"></div>
      <ScrollableSection>
        {isPending && !isFulfilled ? (
          <Column>
            <Loader type="linear" size="large" />
            <Text>Extracting ...</Text>
          </Column>
        ) : (
          <ExtractionResult />
        )}
      </ScrollableSection>
    </Row>
  );
};

export default DataExtraction;
