import React, { useEffect } from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  GroundTruthDataListView,
  ReviewStatus,
  resetListViewPage,
  setCurrentPage,
  setItemPerPage,
  getGroundTruthDataListView,
  downloadGroundTruthsInCSV,
} from "src/store/modelLifeCycleGroundTruthSlice";
import meridianColors from "@amzn/meridian-tokens/base/color";
import DonutChart from "../donutChart";
import PaginationWithDropdown from "src/components/audit/paginationWithDropdown";
import { setSelectedModelId } from "src/store/modelManagementSlice";
import Coachmark from "@amzn/meridian/coachmark";
import _ from "lodash";
import "./modelGroundTruthListView.scss";
import { isValidRange } from "src/utils/numberUtil";
import Link from "@amzn/meridian/link";

const ModelGroundTruthListView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { modelId } = useParams();

  const {
    groundTruthDataListView,
    currentPage,
    itemsPerPage,
    getListViewLoading,
  } = useAppSelector((state) => state.modelLifeCycleGroundTruthSlice);

  const {} = useAppSelector((state) => state.modelLifeCycleSlice);
  const { token } = useAppSelector((state) => state.user);
  const { selectedModel } = useAppSelector((state) => state.modelSlice);

  const getAuditDataStatus = (
    groundTruthDataListView: GroundTruthDataListView,
  ) => {
    return groundTruthDataListView.status === ReviewStatus.REVIEWED
      ? "Yes"
      : "No";
  };

  // triggerred when review page redirect to list view
  useEffect(() => {
    dispatch(resetListViewPage());
  }, []);

  useEffect(() => {
    if (modelId && token && getListViewLoading !== "fulfilled") {
      dispatch(getGroundTruthDataListView(modelId));
    }
  }, [modelId, token]);

  useEffect(() => {
    if (modelId) {
      dispatch(setSelectedModelId(modelId));
    }
  }, [modelId]);

  useEffect(() => {
    if (getListViewLoading === "rejected") {
      navigate(`/validationWorkflow`);
    }
  }, [getListViewLoading]);

  const findNextUnreviewedCase = (
    list: GroundTruthDataListView[],
    sourceId?: string,
  ) => {
    return list
      .filter(
        (groundTruthDataListView: GroundTruthDataListView) =>
          groundTruthDataListView.sourceId !== sourceId,
      )
      .find(
        (groundTruthDataListView: GroundTruthDataListView) =>
          groundTruthDataListView.status === ReviewStatus.UNREVIEWED,
      );
  };

  const numberOfPages = Math.ceil(
    groundTruthDataListView.length / itemsPerPage,
  );
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = Math.min(
    groundTruthDataListView.length,
    firstVisibleIndex + itemsPerPage - 1,
  );

  const tableHeaderRowNames = ["Source ID", "Reviewed", "Reviewer"];

  return (
    <Row widths={["75%", "25%"]}>
      <Column spacingInset={"0 500 500 500"}>
        <BreadcrumbGroup>
          <Breadcrumb onClick={() => navigate(`/validationWorkflow`)}>
            Validation workflow
          </Breadcrumb>
          <Breadcrumb
            onClick={() => navigate(`/validationWorkflow/${modelId}`)}
          >
            {selectedModel.name} - Processed
          </Breadcrumb>
        </BreadcrumbGroup>
        <Row widths={["65%", "35%"]}>
          <Row spacing={"400"}>
            <Column alignmentHorizontal="start" spacing={"300"}>
              <Text type={"d50"}>Ground Truth - Processed</Text>
              <Row spacing={"600"}>
                <Text type="b100">Model Id: {modelId}</Text>
                <Text type="b100">
                  Total Fields: {Object.keys(selectedModel.fields).length}
                </Text>
              </Row>
            </Column>
          </Row>
          <Row alignmentHorizontal="end" widths={["50%", "50%"]}>
            <Button
              type="tertiary"
              onClick={() => {
                modelId &&
                  dispatch(
                    downloadGroundTruthsInCSV({
                      modelId: modelId,
                    }),
                  );
              }}
              data-cy={"download-ground-truths__button"}
            >
              <Icon tokens={downloadLargeTokens}></Icon>
              Ground Truths
            </Button>
            {groundTruthDataListView.length > 0 && (
              <Button
                onClick={() => {
                  const next = findNextUnreviewedCase(groundTruthDataListView);
                  if (next) {
                    navigate(`/validationWorkflow/${modelId}/${next.sourceId}`);
                  } else {
                    navigate(
                      `/validationWorkflow/${modelId}/${groundTruthDataListView[0].sourceId}`,
                    );
                  }
                }}
              >
                Review
              </Button>
            )}
          </Row>
        </Row>
        <Column height="65vh" overflowY="auto">
          <Table
            showDividers={true}
            fixHeaderRows={true}
            stickyHeaderColumn={true}
            headerRows={1}
          >
            <TableRow backgroundColor={meridianColors.colorGray50}>
              {tableHeaderRowNames.map((name) => (
                <TableCell>
                  {name === "Insights Generated" ? (
                    <Row spacing={"none"}>
                      <Coachmark popoverPosition="top" animating={false}>
                        <Text>
                          The number represents: number of field extracted by
                          LLM excluding 'inadequate' result / number of total
                          field part of this model
                        </Text>
                      </Coachmark>
                      <Text type="h200">{name}</Text>
                    </Row>
                  ) : (
                    <Text type="h200">{name}</Text>
                  )}
                </TableCell>
              ))}
            </TableRow>
            {isValidRange(
              firstVisibleIndex,
              lastVisibleIndex,
              0,
              groundTruthDataListView.length,
            ) &&
              _.range(firstVisibleIndex, lastVisibleIndex).map((index) => {
                const totalField = Object.keys(
                  selectedModel.fields,
                ).length.toString();
                return (
                  groundTruthDataListView[index] && (
                    <TableRow
                      highlightOnHover
                      key={groundTruthDataListView[index].sourceId}
                    >
                      <TableCell>
                        <Link
                          type="secondary"
                          onClick={() => {
                            navigate(
                              `/validationWorkflow/${modelId}/${groundTruthDataListView[index].sourceId}`,
                            );
                          }}
                        >
                          {groundTruthDataListView[index].sourceId}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {getAuditDataStatus(groundTruthDataListView[index])}
                      </TableCell>
                      <TableCell>
                        {groundTruthDataListView[index].reviewer || ""}
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
          </Table>
        </Column>
        <PaginationWithDropdown
          currentPage={currentPage}
          totalPages={numberOfPages}
          perPage={itemsPerPage}
          setPerPage={(pageNum) => {
            dispatch(setItemPerPage(pageNum));
            dispatch(setCurrentPage(1));
          }}
          setCurrentPage={(newPage) => dispatch(setCurrentPage(newPage))}
        />
      </Column>
      <Column
        height={"92vh"}
        className="incident-summary"
        alignmentHorizontal="start"
        spacingInset={"500"}
      >
        <Text type="h300">Ground Truth Summary</Text>
        <DonutChart />
      </Column>
    </Row>
  );
};

export default ModelGroundTruthListView;
