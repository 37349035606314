/**
 * download a file given URL
 */
export const downloadFile = (fileUrl: string, fileName: string) => {
  if (fileUrl) {
    let actualUrl = new URL(fileUrl);
    // sanitize by converting to actual url and checking protocol
    // this helps prevent against xss attacks through this vector
    if (actualUrl.protocol === "blob:") {
      const a = document.createElement("a");
      a.href = fileUrl;
      a.download = fileName;
      a.click();
    }
  }
};

/**
 * Create file URL from payload
 */
export const createFileUrl = (payload: string, type: string) => {
  return window.URL.createObjectURL(
    new Blob([payload], {
      type: type,
    }),
  );
};

export const getFileInfoWithProperLength = (
  file: File,
  maxLength: number = 30,
  ellipsis: string = "...",
): { name: string; size: number } => {
  const truncatedName =
    file.name.length > maxLength
      ? `${file.name.substring(0, maxLength - ellipsis.length)}${ellipsis}`
      : file.name;
  return {
    name: truncatedName,
    size: file.size,
  };
};
