import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ModelLifeCycleService from "src/services/modelLifeCycleService";
import CommonService from "src/services/commonService";
import { errorMedium } from "src/utils/toastHelper";

enum ValidationJobStatus {
  PROCESSING = "Processing",
  COMPLETED = "Completed",
  ERROR = "Error",
}

export type GroundTruthPayload = {
  modelId: string;
};

export type GroundTruthOutput = {
  modelId: string;
  uploadId: string;
  fileName: string;
  s3FilePath: string;
  timestamp: string;
  isIndexedToOpenSearch: boolean;
};

export type HistoricalValidationPayload = {
  modelId: string;
  uploadId: string;
};

export type Accuracy = {
  field: string;
  accuracy: number;
};

export interface UploadNewRevisionPayload {
  modelId: string;
  file: File;
}

export interface ValidatePayload {
  modelId: string;
  uploadId: string;
}

export type DownloadPayload = {
  jobId: string;
  s3URL: string;
  fileName: string;
};

export interface GetGroundTruthsCSVPayload {
  modelId: string;
}

export type ValidateOutput = {
  id: string;
  modelId: string;
  modelName: string;
  uploadId: string;
  ownerAlias: string;
  inputFileName: string;
  s3InputFilePath: string;
  s3GroundTruthFilePath: string | undefined;
  s3ExtractedResultFilePath: string | undefined;
  totalGroundTruthsNumber: number | undefined;
  totalReviewedNumber: number | undefined;
  linesProcessed: number;
  linesFailed: number;
  accuracy: Accuracy[];
  timestamp: string;
  status: ValidationJobStatus;
};

export type ValidationWorkflowState = {
  file: File | null;
  currentGroundTruthError: string;
  uploadNewRevisionError: string;
  uploadNewRevisionOutput: GroundTruthOutput | null;
  groundTruthOutputs: GroundTruthOutput[] | null;
  historicalValidations: ValidateOutput[] | null;
  selectedUploadId: string;
};

export const getCurrentGroundTruth = createAsyncThunk<
  GroundTruthOutput[],
  GroundTruthPayload
>("getCurrentGroundTruth", async ({ modelId }) => {
  const { data } = await ModelLifeCycleService.getCurrentGroundTruth(modelId);
  return data;
});

export const getHistoricalValidation = createAsyncThunk<
  ValidateOutput[],
  HistoricalValidationPayload
>("getHistoricalValidation", async ({ modelId, uploadId }) => {
  const { data } = await ModelLifeCycleService.getHistoricalValidation(
    modelId,
    uploadId,
  );
  if (data.length === 0) {
    errorMedium(
      "There is no historical data for the selected file, please validate first.",
    );
  }
  return data;
});

export const uploadNewRevision = createAsyncThunk<
  GroundTruthOutput,
  UploadNewRevisionPayload
>("uploadNewRevision", async (payload: UploadNewRevisionPayload) => {
  const { data } = await ModelLifeCycleService.uploadNewRevision(payload);
  return data;
});

export const validate = createAsyncThunk<ValidateOutput, ValidatePayload>(
  "validate",
  async (payload: ValidatePayload) => {
    const { data } = await ModelLifeCycleService.validate(payload);
    return data;
  },
);

export const download = createAsyncThunk(
  "download",
  CommonService.getModelLifeCyclePreSignedUrlAndDownload,
);

const initialState: ValidationWorkflowState = {
  file: null,
  currentGroundTruthError: "",
  uploadNewRevisionError: "",
  uploadNewRevisionOutput: null,
  groundTruthOutputs: null,
  historicalValidations: null,
  selectedUploadId: "",
};

const { reducer, actions } = createSlice({
  name: "validationWorkflow",
  initialState,
  reducers: {
    setSelectedUploadId: (state, action) => {
      state.selectedUploadId = action.payload;
    },
    setFile: (state, action) => {
      state.file = action.payload;
    },
    resetHistoricalValidation: (state) => {
      state.historicalValidations = null;
    },
    resetCurrentGroundTruthError: (state) => {
      state.currentGroundTruthError = "";
    },
    resetUploadNewRevisionError: (state) => {
      state.uploadNewRevisionError = "";
    },
    resetGroundTruthOutput: (state) => {
      state.groundTruthOutputs = null;
      state.uploadNewRevisionOutput = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentGroundTruth.rejected, (state, action) => {
      state.groundTruthOutputs = null;
    });
    builder.addCase(
      getCurrentGroundTruth.fulfilled,
      (state, action: PayloadAction<GroundTruthOutput[]>) => {
        state.groundTruthOutputs = action.payload;
      },
    );
    builder.addCase(
      getHistoricalValidation.fulfilled,
      (state, action: PayloadAction<ValidateOutput[]>) => {
        state.historicalValidations = action.payload;
      },
    );
    builder.addCase(uploadNewRevision.rejected, (state, action) => {
      state.uploadNewRevisionError =
        "Failed to upload! File may have unmatched fields.";
    });
    builder.addCase(
      uploadNewRevision.fulfilled,
      (state, action: PayloadAction<GroundTruthOutput>) => {
        state.uploadNewRevisionOutput = action.payload;
      },
    );
  },
});

export const {
  setFile,
  setSelectedUploadId,
  resetCurrentGroundTruthError,
  resetUploadNewRevisionError,
  resetGroundTruthOutput,
  resetHistoricalValidation,
} = actions;

export default reducer;
