import store from "src/store/store";
import { createToast } from "src/store/toastsSlice";
import { AlertType } from "@amzn/meridian/alert/alert";

enum Timeout {
  Short = 3000,
  Medium = 6000,
  Long = 10000,
}

function showToast(type: AlertType, message: string, timeout: number) {
  store.dispatch(
    createToast({
      type: type,
      message: message,
      timeout: timeout,
    }),
  );
}

export const success = (message: string) =>
  showToast("success", message, Timeout.Short);
export const error = (message: string) =>
  showToast("error", message, Timeout.Short);
export const successMedium = (message: string) =>
  showToast("success", message, Timeout.Medium);
export const errorMedium = (message: string) =>
  showToast("error", message, Timeout.Medium);
export const successLong = (message: string) =>
  showToast("success", message, Timeout.Long);
export const errorLong = (message: string) =>
  showToast("error", message, Timeout.Long);

export const warning = (message: string) =>
  showToast("warning", message, Timeout.Short);
