import React, { useState } from "react";
import Select, { SelectOption } from "@amzn/meridian/select";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  Field,
  ModelView,
  setSelectedModelId,
} from "src/store/modelManagementSlice";
import { createSearchRegExp } from "src/helpers";

const ModelSelect = () => {
  const { selectedModelId, modelView } = useAppSelector(
    (state) => state.modelSlice,
  );
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const searchRegExp = createSearchRegExp(searchQuery);

  return (
    <Select
      label={"Please select your model"}
      value={selectedModelId}
      onChange={async (modelId) => {
        dispatch(setSelectedModelId(modelId));
      }}
      searchQuery={searchQuery}
      onSearch={setSearchQuery}
      placeholder={"please select..."}
      size="medium"
      width={"100%"}
      data-cy={"model__select"}
    >
      {Object.values(modelView)
        .filter((modelView: ModelView) => searchRegExp.test(modelView.name))
        .map((model) => (
          <SelectOption key={model.id} value={model.id} label={model.name} />
        ))}
    </Select>
  );
};
export default ModelSelect;
