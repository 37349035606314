import React from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import RecordLine from "../shared/components/recordLine";
import { setSelectedFieldId } from "src/store/modelManagementSlice";

const FieldRecords = () => {
  const dispatch = useAppDispatch();
  const { selectedModelId, selectedModel } = useAppSelector(
    (state) => state.modelSlice,
  );

  return selectedModelId === "" ? (
    <Column
      height={"60%"}
      alignmentVertical={"center"}
      alignmentHorizontal={"center"}
    >
      <Text>Please select your model</Text>
    </Column>
  ) : (
    <Column spacing={"300"}>
      {Object.values(selectedModel.fields).map((field) => (
        <RecordLine
          key={field.id}
          record={field}
          onClick={() => {
            dispatch(setSelectedFieldId(field.id));
          }}
          data-cy={"existing-field__list"}
        />
      ))}
    </Column>
  );
};

export default FieldRecords;
