import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import userSlice from "src/store/userSlice";
import modelSlice from "src/store/modelManagementSlice";
import errorBoundarySlice from "src/store/errorBoundarySlice";
import dataExtractionSlice from "src/store/dataExtractionSlice";
import toastsSlice from "src/store/toastsSlice";
import batchExtractionSlice from "src/store/batchExtractionSlice";
import modelLifeCycleSlice from "src/store/modelLifeCycleSlice";
import modelLifeCycleGroundTruthSlice from "src/store/modelLifeCycleGroundTruthSlice";
import auditSlice from "src/store/auditSlice";
import auditDataUploadSlice from "src/store/auditDataUploadSlice";

export const reducers = {
  user: userSlice,
  toasts: toastsSlice,
  errorBoundary: errorBoundarySlice,
  modelSlice: modelSlice,
  dataExtractionSlice: dataExtractionSlice,
  batchDataExtractionPageSlice: batchExtractionSlice,
  modelLifeCycleSlice: modelLifeCycleSlice,
  auditSlice: auditSlice,
  auditDataUploadSlice: auditDataUploadSlice,
  modelLifeCycleGroundTruthSlice: modelLifeCycleGroundTruthSlice,
};

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // to allow non serializable objects to be stored in redux (eg: Map, Set, classes etc.)
    }),
});
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
