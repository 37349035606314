import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LOADING_STATE } from "src/types/constants";
import ModelLifeCycleService from "src/services/modelLifeCycleService";
import { INADEQUATE_INFORMATION } from "src/components/audit/helpers";
import { createFileUrl, downloadFile } from "src/utils/fileUtil";

export enum ReviewStatus {
  REVIEWED = "Reviewed",
  UNREVIEWED = "Unreviewed",
}

export enum ReviewResult {
  LLM_CORRECT = "LLM is Correct",
  HUMAN_CORRECT = "Human is correct",
  INADEQUATE = "Inadequate Information",
  NEITHER = "Neither is correct",
  BOTH_CORRECT = "Both are correct",
}

export type GroundTruthField = {
  fieldName: string;
  userAnswer: string;
  llmClarification: string;
  llmAnswer: string;
  confirmedAnswer: string | string[];
  reviewResult: ReviewResult;
};

export type GroundTruthData = {
  modelId: string;
  sourceId: string;
  sourceText: string;
  fields: GroundTruthField[];
  timestamp: string;
  status: ReviewStatus;
  reviewer: string;
};

export type GroundTruthDataListView = {
  sourceId: string;
  status: ReviewStatus;
  reviewer: string;
};

const MULTI_OPTION_DELIMITER = "|";

export type GroundTruthState = {
  groundTruthListIndex: number;
  groundTruthDataListView: GroundTruthDataListView[];
  groundTruthDataTotalCount: number;
  groundTruthDataReviewedCount: number;
  selectedGroundTruthData: GroundTruthData;
  itemsPerPage: number;
  currentPage: number;
  groundTruthDataFieldIndex: number;
  getListViewLoading: LOADING_STATE;
  getGroundTruthDataLoading: LOADING_STATE;
  getGroundTruthDataStatistics: LOADING_STATE;
  updateGroundTruthDataLoading: LOADING_STATE;
};

export const initialGroundTruthData: GroundTruthData = {
  sourceId: "",
  modelId: "",
  sourceText: "",
  fields: [],
  timestamp: "",
  status: ReviewStatus.UNREVIEWED,
  reviewer: "",
};

const initialState: GroundTruthState = {
  groundTruthListIndex: -1,
  groundTruthDataListView: [],
  groundTruthDataTotalCount: 0,
  groundTruthDataReviewedCount: 0,
  selectedGroundTruthData: initialGroundTruthData,
  itemsPerPage: 50,
  currentPage: 1,
  groundTruthDataFieldIndex: -1,
  getListViewLoading: "idle",
  getGroundTruthDataLoading: "idle",
  updateGroundTruthDataLoading: "idle",
  getGroundTruthDataStatistics: "idle",
};

export const GROUND_TRUTH_UPLOAD_URL = "/model_life_cycle/upload";

export const getGroundTruthDataListView = createAsyncThunk(
  "modelLifeCycle/groundTruth/getGroundTruthDataListView",
  async (modelId: string) => {
    const { data } =
      await ModelLifeCycleService.getGroundTruthDataListView(modelId);
    return data;
  },
);

export const getGroundTruthData = createAsyncThunk(
  "modelLifeCycle/groundTruth/getGroundTruthData",
  async (payload: { modelId: string; sourceId: string }) => {
    const { data } = await ModelLifeCycleService.getGroundTruthData(
      payload.modelId,
      payload.sourceId,
    );
    return data;
  },
);

export const downloadGroundTruthsInCSV = createAsyncThunk(
  "modelLifeCycle/getetGroundTruthsCSV",
  async (payload: { modelId: string }) => {
    const { data } =
      await ModelLifeCycleService.downloadGroundTruthsInCSV(payload);
    return data;
  },
);

export const updateGroundTruthData = createAsyncThunk(
  "modelLifeCycle/groundTruth/updateGroundTruthData",
  async (payload: { groundTruthData: GroundTruthData; reviewer: string }) => {
    const { data } = await ModelLifeCycleService.updateGroundTruthData({
      ...payload.groundTruthData,
      fields: payload.groundTruthData.fields.map(
        (groundTruthField: GroundTruthField) =>
          toGroundTruthFields(groundTruthField),
      ),
      reviewer: payload.reviewer,
    });
    return data;
  },
);

export const toGroundTruthFields = (item: GroundTruthField) => {
  return {
    ...item,
    confirmedAnswer:
      typeof item.confirmedAnswer === "string"
        ? item.confirmedAnswer
        : item.confirmedAnswer.join(MULTI_OPTION_DELIMITER),
  };
};

export const setLLMAnswerForUnreviewedGroundTruthData = (
  groundTruthData: GroundTruthData,
) => {
  if (groundTruthData.status === ReviewStatus.UNREVIEWED) {
    groundTruthData.fields.forEach((groundTruthField) => {
      if (!groundTruthField.confirmedAnswer) {
        if (groundTruthField.llmAnswer) {
          groundTruthField.confirmedAnswer = groundTruthField.llmAnswer;
          groundTruthField.reviewResult = ReviewResult.LLM_CORRECT;
        } else {
          groundTruthField.confirmedAnswer = INADEQUATE_INFORMATION;
          groundTruthField.reviewResult = ReviewResult.INADEQUATE;
        }
      }
    });
  }
  return groundTruthData;
};

/** Data Extraction Page Slice */
const { reducer, actions } = createSlice({
  name: "modelLifeCycle/groundTruthReviewPageSlice",
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setItemPerPage: (state, { payload }) => {
      state.itemsPerPage = payload;
    },
    setFieldIndex: (state, { payload }) => {
      state.groundTruthListIndex = payload;
    },
    setFieldConfirmedAnswer: (state, { payload }) => {
      state.selectedGroundTruthData.fields[payload.fieldIndex].confirmedAnswer =
        payload.confirmedAnswer;
      if (
        payload.reviewResult === ReviewResult.BOTH_CORRECT ||
        payload.reviewResult === ReviewResult.INADEQUATE
      ) {
        state.selectedGroundTruthData.fields[payload.fieldIndex].reviewResult =
          payload.reviewResult;
      } else {
        if (
          payload.confirmedAnswer ===
          state.selectedGroundTruthData.fields[payload.fieldIndex].llmAnswer
        ) {
          state.selectedGroundTruthData.fields[
            payload.fieldIndex
          ].reviewResult = ReviewResult.LLM_CORRECT;
        } else if (
          payload.confirmedAnswer ===
          state.selectedGroundTruthData.fields[payload.fieldIndex].userAnswer
        ) {
          state.selectedGroundTruthData.fields[
            payload.fieldIndex
          ].reviewResult = ReviewResult.HUMAN_CORRECT;
        } else {
          state.selectedGroundTruthData.fields[
            payload.fieldIndex
          ].reviewResult = ReviewResult.NEITHER;
        }
      }
    },
    resetListViewPage: (state) => {
      state.getListViewLoading = "idle";
      state.getGroundTruthDataLoading = "idle";
      state.getGroundTruthDataStatistics = "idle";
      state.updateGroundTruthDataLoading = "idle";
    },
    resetGroundTruthReviewPage: (state) => {
      state.getGroundTruthDataLoading = "idle";
      state.getGroundTruthDataStatistics = "idle";
      state.updateGroundTruthDataLoading = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroundTruthDataListView.pending, (state) => {
      state.getListViewLoading = "pending";
      state.groundTruthDataTotalCount = 0;
      state.groundTruthDataReviewedCount = 0;
    });
    builder.addCase(
      getGroundTruthDataListView.fulfilled,
      (state, { payload }) => {
        state.getListViewLoading = "fulfilled";
        state.groundTruthDataListView = payload.sort(
          (a: GroundTruthDataListView, b: GroundTruthDataListView) => {
            // Sort by status (unreview first, then base on case id)
            return a.status === b.status
              ? a.sourceId.localeCompare(b.sourceId)
              : a.status === ReviewStatus.UNREVIEWED
                ? -1
                : 1;
          },
        );
        state.groundTruthDataTotalCount = state.groundTruthDataListView.length;
        state.groundTruthDataReviewedCount =
          state.groundTruthDataListView.filter(
            (gt: GroundTruthDataListView) =>
              gt.status === ReviewStatus.REVIEWED,
          ).length;
      },
    );
    builder.addCase(getGroundTruthDataListView.rejected, (state) => {
      state.getListViewLoading = "rejected";
      state.groundTruthDataTotalCount = 0;
      state.groundTruthDataReviewedCount = 0;
    });

    builder.addCase(updateGroundTruthData.pending, (state) => {
      state.updateGroundTruthDataLoading = "pending";
    });
    builder.addCase(updateGroundTruthData.fulfilled, (state, { payload }) => {
      state.updateGroundTruthDataLoading = "fulfilled";
      const index = state.groundTruthDataListView.findIndex(
        (groundTruthDataListView: GroundTruthDataListView) =>
          groundTruthDataListView.sourceId === payload.sourceId,
      );
      if (index !== -1) {
        state.groundTruthDataListView[index].status = payload.status;
      }
    });
    builder.addCase(updateGroundTruthData.rejected, (state) => {
      state.updateGroundTruthDataLoading = "rejected";
    });

    builder.addCase(getGroundTruthData.pending, (state) => {
      state.getGroundTruthDataLoading = "pending";
    });
    builder.addCase(getGroundTruthData.fulfilled, (state, { payload }) => {
      state.getGroundTruthDataLoading = "fulfilled";
      state.selectedGroundTruthData =
        setLLMAnswerForUnreviewedGroundTruthData(payload);
    });
    builder.addCase(getGroundTruthData.rejected, (state) => {
      state.getGroundTruthDataLoading = "rejected";
    });
    builder.addCase(
      downloadGroundTruthsInCSV.fulfilled,
      (state, { payload }) => {
        if (payload) {
          // download file
          downloadFile(createFileUrl(payload, "text/csv"), "ground_truths.csv");
        }
      },
    );
  },
});

export const {
  setFieldConfirmedAnswer,
  setCurrentPage,
  setItemPerPage,
  resetGroundTruthReviewPage,
  resetListViewPage,
} = actions;

export default reducer;
