import Auth from "@aws-amplify/auth";

export async function Init(): Promise<void> {
  const config = {
    stage: "beta",
    aws_cognito_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:269a1bbe-3e2a-41fe-940f-c3ae2fd73df9",
    aws_user_pools_id: "us-east-1_DcuIOCq2D",
    aws_user_pools_web_client_id: "1vr12km8aqj28oci81jn8g4ni4",
    Auth: {
      identityPoolId: "us-east-1:269a1bbe-3e2a-41fe-940f-c3ae2fd73df9",
      region: "us-east-1",
      userPoolId: "us-east-1_DcuIOCq2D",
      userPoolWebClientId: "1vr12km8aqj28oci81jn8g4ni4",
    },
    oauth: {
      domain: `whs-data-excavator-beta-na.auth.us-east-1.amazoncognito.com`,
      redirectSignIn: "https://beta.na.excavator.whs.amazon.dev",
      redirectSignOut: "https://beta.na.excavator.whs.amazon.dev",
      scope: ["openid", "profile"],
      responseType: "code",
    },
  };
  Auth.configure(config);
}
